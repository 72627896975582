<script>
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();

	export let value = '';
	export let id = undefined;
	export let label = undefined;
	export let name = 'textarea';
	export let placeholder = '';
	export let tabIndex = undefined;
	export let maxlength = undefined;
	export let rows = 4;

	export let useBaseClass = true;
	export let required = false;
	export let disabled = false;
	export let autofocus = false;
</script>

<div class="form-group">
	{#if label}
		<label for={id} class="block text-sm font-medium">
			{label}
			{#if required}<span class="text-red-500">*</span>{/if}
		</label>
	{/if}
	<div class={label ? 'mt-1' : ''}>
		<div class="relative">
			<!-- svelte-ignore a11y-autofocus -->
			<textarea
				{name}
				{id}
				{required}
				bind:value
				on:change={(e) => dispatch('change', { id: e.target.id, value: e.target.value })}
				on:input={(e) => dispatch('input', { id: e.target.id, value: e.target.value })}
				on:blur={(e) => dispatch('blur', { id: e.target.id, value: e.target.value })}
				on:click={(e) => dispatch('click', { id: e.target.id, value: e.target.value })}
				{maxlength}
				{placeholder}
				{tabIndex}
				{rows}
				class="
					{useBaseClass ? 'form-input w-full ' : ''} 
					{disabled ? 'form-input-disabled cursor-not-allowed' : ''}
					{$$props.class}
				"
				{disabled}
				{autofocus}
			/>
		</div>
	</div>
</div>
